<script setup>
import phoneImg from "~/assets/images/statics/phone.jpg"
import { useBackend } from "~/composables/Backend";

useHead({
  title: "O nas - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
});

const backend = useBackend();
const {data: brands} = useAsyncData(async () => await backend.getBrands());

</script>

<template>
  <Container>
    <div class="">
      <div class="flex flex-col lg:flex-row items-center">
        <div class="basis-1/2 shrink-0 flex-grow-0 order-2 lg:order-1">
          <div class="text-3xl text-center lg:text-left lg:text-5xl">
            <h1 class="mb-6 leading-tight">VEHIS - TU ZNAJDZIESZ SWÓJ NOWY SAMOCHÓD</h1>
          </div>
          <div class="text-xl text-center lg:text-left leading-normal pt-8 lg:pt-8 font-extralight">
            <div>VEHIS to platforma oferująca samochody wszystkich marek wraz z
              <span class="font-normal">finansowaniem, ubezpieczeniem</span> oraz
              <span class="font-normal">wsparciem serwisowym</span>.
            </div>
            <div class="pt-6">
              Z naszych usług korzysta już ponad
              <span class="font-normal">8000 klientów</span>.
            </div>
          </div>
        </div>
        <div class="basis-1/2 shrink-0 flex-grow-0 order-1 lg:order-2">
          <img src="@/assets/images/statics/about-cars.jpg" alt="cars">
        </div>
      </div>

      <StaticSeparator />

      <StaticHeader subheader="Jesteśmy członkiem ZPL, największej organizacji reprezentującej branżę leasingową w Polsce">Współpracujemy z najlepszymi</StaticHeader>

      <div class="flex justify-center mb-10">
        <VectorZplLogo class="w-72 grayscale brightness-0" />
      </div>

      <div class="flex justify-center mb-10">
        <div class="text-xl font-extralight pt-3 text-center">Jesteśmy partnerem serwisu <a href="https://moto.rp.pl/"  rel="noopener noreferrer" target="_blank">moto.rp.pl</a>, współpracując z "Rzeczpospolitą" - najbardziej opiniotwórczym medium dekady.
        </div>
      </div>

      <div class="flex justify-center mb-10">
        <VectorLogoRP class="w-72 grayscale brightness-0" />
      </div>

      <div class="flex justify-center mb-10">
        <div class="text-xl font-extralight pt-3 text-center">W naszej ofercie znajdziesz nowe i używane samochody najpopularniejszych marek</div>
      </div>

      <StaticCarousel class="lg:mx-12">
        <ButtonBrand v-for="brand in brands?.data"
                     :name=brand.name :icon=brand.svg
                     :href="{name: 'brand', params: {brand: brand.name.toLowerDashed()}}"
        />
      </StaticCarousel>

      <div class="w-full text-center font-extralight text-xl pt-14">Oferujemy ubezpieczenia wyłącznie renomowanych towarzystw ubezpieczeniowych</div>

      <div class="flex justify-center py-12">
        <div class="lg:w-1/3 flex justify-center items-center grayscale brightness-0 gap-10 px-6">
          <div class="basis-1/4 shrink grow">
            <img src="@/assets/images/statics/insurance/ergo_hestia.png" alt="" />
          </div>
          <div class="basis-1/4 shrink grow">
            <img src="@/assets/images/statics/insurance/axa.png" alt="" />
          </div>
          <div class="basis-1/4 shrink grow">
            <img src="@/assets/images/statics/insurance/benefia.png" alt="" />
          </div>
          <div class="basis-1/4 shrink grow">
            <img src="@/assets/images/statics/insurance/pzu.png" alt="" />
          </div>
        </div>
      </div>

      <div class="lg:mx-32 my-12 space-y-12">
        <StaticArticleBox
          preheader="Inwestor strategiczny"
          header="Silne fundamenty"

          target="_blank"
          leftSideImage
        >
          <template v-slot:svg>
            <VectorEnterpriseInvestorsLogo />
          </template>
          <div class="text-base font-extralight leading-normal inline m-0 space-y-2">
            <p>
              Inwestorem strategicznym VEHIS jest Enterprise Investors
              <span class="font-normal">jedna z najstarszych i największych firm private equity</span> w Europie Środkowo-Wschodniej. EI zarządza portfelem inwestycyjnym o wartości 2,5&nbsp;MLD&nbsp;€.
            </p>
            <p class="pb-4">
              <NuxtLink to="https://www.ei.com.pl/pl/portfolio/" target="_blank" class="font-extralight text-azure text-sm">Dowiedz się więcej o EI</NuxtLink>
            </p>

            <p>
              W zakresie pozyskania finansowania VEHIS współpracuje z czołowymi instytucjami finansowymi, m.in. od 2022 z firmą <span class="font-normal">Santander Corporate & Investment Banking.</span>
            </p>
            <p>
              W maju 2024 r. do grona renomowanych międzynarodowych instytucji finansowych, które współpracują z VEHIS w ramach pionierskiego na rynku polskim finansowania w formie “warehouse financing” dołączył <span class="font-normal">Jefferies Financial Group Inc.</span>
            </p>
          </div>
        </StaticArticleBox>

        <StaticArticleBox preheader="Program wsparcia serwisowego"
                          header="1500 autoryzowanych oraz niezależnych warsztatów"
                          :src="phoneImg"
                          buttonLabel="Lista partnerów serwisowych"
                          :href="backend.getDownloadLinkForFile('services')"
                          right-side-image
        >
          <div class="font-light">
            W ramach
            <b>Programu wsparcia serwisowego</b> współpracujemy z szeroką listą autoryzowanych i niezależnych partnerów serwisowych, którzy przy naszym wsparciu dbają o
            <b>sprawność techniczną samochodów</b> naszych klientów.
          </div>
        </StaticArticleBox>

      </div>

      <StaticSeparator class="w-1/3" />

      <StaticHeader>To my tworzymy VEHIS</StaticHeader>

      <StaticTwoColumn no-StaticSeparator centered>
        <template v-slot:one>
          <div class="space-y-4 font-extralight">
            <div>Każdy z nas jest ekspertem w swojej dziedzinie. Razem
              <span class="font-normal">tworzymy zgrany i wykwalifikowany zespół</span>.
            </div>

            <div>Jako
              <span class="font-normal">eksperci branży finansowej i motoryzacyjnej</span> dobrze znamy rynek,
              a przede wszystkim potrzeby klientów.
            </div>

            <div>Dlatego założyliśmy VEHIS – dla wszystkich, którzy oczekują
              <span class="font-normal">prostych rozwiązań i lepszych ofert</span>.
            </div>

            <div>Dziś nasz zespół liczy 230 osób i ciągle się rozwija.</div>

            <div>
            <span class="font-normal">Jeżeli chcesz zostać jego częścią napisz na
              <a href="mailto:praca@vehis.pl" class="text-azure">praca@vehis.pl</a>
            </span>
            </div>
          </div>
        </template>
        <template v-slot:two>
          <img src="@/assets/images/statics/vehis.jpg" alt="VEHIS" />
        </template>
      </StaticTwoColumn>

      <StaticSeparator class="w-1/3" />

      <StaticHeader>Zarząd VEHIS</StaticHeader>

      <div class="grid lg:grid-cols-2 gap-12">
        <div class="lg:col-span-2">
          <StaticArticleBox preheader="Prezes Zarządu" header="Grzegorz Tracz">
            <div class="font-extralight">
              <div class="pb-6">Absolwent Wydziału Prawa Uniwersytetu Jagiellońskiego oraz Uniwersytetu w Heidelbergu.</div>
              <div>W swojej bogatej karierze zawodowej pełnił funkcje m.in:</div>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-1 lg:gap-12">
                <ul class="list-disc list-inside pl-1 space-y-1">
                  <li>Członka Zarządu / Prezesa Zarządu
                    <b>Volkswagen Bank Polska SA</b> (1999-2005)
                  </li>
                  <li>Wiceprezesa / Członka Zarządu
                    <b>Getin Bank SA / Getin Noble Bank SA</b> (2006-2016)
                  </li>
                </ul>
                <ul class="list-disc list-inside pl-1 space-y-1">
                  <li>Przewodniczącego Rady Nadzorczej
                    <b>Idea Bank</b> na Ukrainie (2009-2014)
                  </li>
                  <li>Prezesa Zarządu
                    <b>Getin Leasing SA</b> (2009-2016) oraz
                    <b>Alior Leasing Sp. z o.o.</b> (2016-2018).
                  </li>
                </ul>
              </div>
            </div>
          </StaticArticleBox>
        </div>
        <div>
          <StaticArticleBox preheader="Członek Zarządu" header="Ireneusz Meller">
            <div class="font-extralight">
              <div class="pb-6">Absolwent Wydziału Zarządzania i Marketingu Akademii Ekonomicznej w Katowicach.</div>
              <div>Podczas długoletniej kariery zawodowej piastował stanowiska m.in:</div>
              <ul class="list-disc list-inside pl-1 space-y-1">
                <li>
                  Stanowiska kierownicze
                  <b>Volkswagen Bank Polska SA</b> (1999-2008)
                </li>
                <li>Dyrektora Finansowego / Członka Zarządu
                  <b>Idea Bank</b> na Ukrainie (2010-2013)
                </li>
                <li>Członka Zarządu
                  <b>Getin Leasing SA</b> (2011-2016),
                  <b>Getin Fleet SA</b> (2011-2016) oraz
                  <b>Alior Leasing Sp. z o.o.</b> (2016-2018).
                </li>
              </ul>
            </div>
          </StaticArticleBox>
        </div>
        <div>
          <StaticArticleBox preheader="Członek Zarządu" header="Marcin Wykręt">
            <div class="font-extralight">
              <div class="pb-6">Absolwent Wydziału Prawa i Administracji Uniwersytetu Śląskiego w Katowicach oraz Wyższej Szkoły Psychologii Społecznej.</div>
              <div>Renomę w branży finansowo-motoryzacyjnej zdobywał pełniąc funkcje m.in:</div>
              <ul class="list-disc list-inside pl-1 space-y-1">
                <li>
                  Stanowiska kierownicze
                  <b>Volkswagen Bank Polska SA</b> (1997-2006)
                </li>
                <li>Dyrektora Zarządzającego Obszarem Finansowania Pojazdów w
                  <b>Getin Bank SA / Getin Noble Bank SA</b> (2008-2016)
                </li>
                <li>Członka Zarządu
                  <b>Getin Leasing SA</b> (2010-2016) oraz
                  <b>Alior Leasing Sp. z o.o.</b> (2016-2018).
                </li>
              </ul>
            </div>
          </StaticArticleBox>
        </div>
        <div>
          <StaticArticleBox preheader="Członek Zarządu" header="Jan Bujak">
            <div class="font-extralight">
              <div class="pb-6">Absolwent Wydziału Prawa Uniwersytetu Jagiellońskiego, a także Programu Executive MBA na Uniwersytecie w Bristolu i École Nationale des Ponts et Chaussées w Paryżu.</div>
              <div>Bogate doświadczenie zdobywał na stanowiskach m.in:</div>
              <ul class="list-disc list-inside pl-1 space-y-1">
                <li>Dyrektora Finansowego / Wiceprezesa / Prezesa Zarządu
                  <b>Fortis Bank Polska SA</b> (1999-2009)
                </li>
                <li>Wiceprezesa Zarządu / Dyrektora Finansowego w
                  <b>BNP Paribas Bank Polska</b> (2009-2016)
                </li>
                <li>Członka Zarządu w
                  <b>Alior Leasing Sp. z o.o.</b> (2017-2018).
                </li>
              </ul>
            </div>
          </StaticArticleBox>
        </div>
        <div>
          <StaticArticleBox preheader="Członek Zarządu" header="Jacek Pasławski">
            <div class="font-extralight">
              <div class="pb-6">Absolwent Wydziału Zarządzania Akademii Górniczo-Hutniczej w Krakowie.</div>
              <div>Pomysłodawca i założyciel wielu startupów technologicznych.
                W trakcie kariery zawodowej pełnił między innymi następujące funkcje:
              </div>
              <ul class="list-disc list-inside pl-1 space-y-1">
                <li>Członka Zarządu / Prezesa Zarządu
                  <b>INTERIA.PL SA</b> (2002-2006)
                </li>
                <li>Prezesa Zarządu agencji reklamowej
                  <b>Novem sp. z o.o.</b> (2006-2020).
                </li>
              </ul>
            </div>
          </StaticArticleBox>
        </div>
      </div>

      <StaticSeparator />

      <StaticHeader>Relacje inwestorskie</StaticHeader>

      <StaticTwoColumn class="lg:mx-30 my-0 lg:my-8">
        <template v-slot:one>
          <div class="font-light pb-6 lg:pb-12">
            Zapraszamy do zapoznania się z najnowszymi informacjami dot. Grupy VEHIS.
          </div>
          <div class="flex justify-center">
            <ButtonRounded color="gray" :href="{name: 'static-information'}" fit>Sprawozdania i komunikaty</ButtonRounded>
          </div>
        </template>
        <template v-slot:two>
          <div>
            <b>Kontakt w sprawach komunikacji inwestorskiej:</b>
          </div>
          <div class="space-y-3 pt-6">
            <p class="block text-sm pb-2">
              <IconPhone class="inline-block mr-2" />
              +48 32 308 84 44
            </p>
            <p class="block text-sm pb-2">
              <IconMail class="inline-block mr-2" />
              bok@vehis.pl
            </p>
          </div>

        </template>
      </StaticTwoColumn>

    </div>
  </Container>
</template>
